// import logo from "./logo.svg";
import "./App.css";
// import Dashboard from "./components/Dashboard";
import { RouterProvider } from "react-router-dom";
import routes from "./routes";
// import { ToastBar } from "react-hot-toast";

function App() {
	return (
		<div className="App">
			<RouterProvider router={routes} />
			{/* <Dashboard /> */}
		</div>
	);
}

export default App;

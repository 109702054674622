import React, { useState } from 'react'
import { toast } from 'react-hot-toast';
import { useAuth } from '../../Context/useAuth';

const Profile = () => {
    const {setApi}=useAuth()
    const [getApi, setGetApi] = useState("");
    	const handleApiChange = (e) => {
        setGetApi(e.target.value);
      };
      const user=JSON.parse(localStorage.getItem("user"))
    const handleSubmit = (e) => {
        e.preventDefault();
        toast.success("Successfully Loggedin");
        localStorage.setItem("api", JSON.stringify({ getApi }));
        const storeApi = localStorage.getItem("api");
        setApi(JSON.parse(storeApi.getApi));
    }
  return (
      <div className="min-h-screen bg-[#390f64] flex flex-col items-center justify-center">
          <h1 className='text-4xl text-white'>{user?.name}</h1>
      <form onSubmit={handleSubmit} className="flex flex-col p-6 gap-6 w-2/4">
        <label className="text-white text-start">API KEY:</label>
        <input
          placeholder="API Key"
          className="rounded  text-black text-center"
          type="text"
          required
          onChange={handleApiChange}
        />
        <button
          type="submit"
          className="bg-gray-300 px-2 py-1 font-semibold rounded w-40 mx-auto"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Profile
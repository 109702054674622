import {  createBrowserRouter } from "react-router-dom";
import Dashboard from "./components/Dashboard";
import Login from "./components/Login/Login";
import ProtectedRoute from "./components/ProtectedRoute";
import AllCalls from "./components/Calls/AllCalls";
import Home from "./components/Calls/Home";
import Profile from "./components/Profile/Profile";

const router = createBrowserRouter([
  {
    path: "/",
    element: (
      <ProtectedRoute>
        <Dashboard />
      </ProtectedRoute>
    ),
    children: [
      {
        path: "/",
        element: <Profile />,
      },
      {
        path: "/overview",
        element: <Home />,
      },
      {
        path: "/calls",
        element: <AllCalls />,
      },
    ],
  },
  {
    path: "/login",
    element: <Login />,
  },
]);
export default router;
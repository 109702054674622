import { Spin } from "antd";
import React, { useEffect, useRef, useState } from "react";
// import CountUp from "react-countup/build/CountUp";
import CountUp from "react-countup";
// import { Pie, PieController } from "chart.js";
// import { Chart } from "react-google-charts";
import { PieChart } from "react-minimal-pie-chart";

const Home = () => {
	const api = JSON.parse(localStorage.getItem("api"));
	const [dataOfCalls, setDataOfCalls] = useState([]);
	const dataSource = dataOfCalls.data;
	const [loader, setLoader] = useState(false);

	useEffect(() => {
		setLoader(true);
		fetch("https://arcadia.mobilize.ai/api/calls", {
			headers: {
				Authorization: `Bearer ${api?.getApi}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle the API response data
				setDataOfCalls(data);
				setLoader(false);
			})
			.catch((error) => {
				// Handle any errors that occur during the fetch request
				console.error(error);
			});
	}, []);

	let todayDuration = 0;
	let totalDuration = 0;
	const statusValues = ["ANSWERED", "AMD", "DNC", "INTERACTION"];
	// const currentDate = new Date().toLocaleDateString();
	const currentDate = new Date().toLocaleDateString();

	// Calculate the previous date
	const previousDate = new Date();
	previousDate.setDate(previousDate.getDate() - 1);
	const previousDateString = previousDate.toLocaleDateString();
	let statusCounts = {
		answered: 0,
		missed: 0,
		voicemail: 0,
	};
	let totalCalls = 0;
	statusValues.forEach((status) => {
		statusCounts[status] = 0;
	});
	dataSource?.forEach((item) => {
		// Calculate call duration in seconds

		const start = new Date(item.start);
		const end = new Date(item.end);
		const duration = Math.round((end - start) / 1000);
		totalDuration += duration;

		// Increment status count
		statusCounts[item.status] += 1;
		const callDate = new Date(item.start).toLocaleDateString();
		// Increment total call count
		totalCalls += 1;
		if (callDate === previousDateString) {
			const start = new Date(item.start);
			const end = new Date(item.end);
			const duration = Math.round((end - start) / 1000);
			todayDuration += duration;
		}
	});
	const statusPercentage = {};
	statusValues.forEach((status) => {
		statusPercentage[status] = (
			(statusCounts[status] / totalCalls) *
			100
		).toFixed(2);
	});
	//show chart using chart js
	// Define the data for the pie chart
	const pieChartData = [
    {
      title: "ANSWERED",
      value: parseFloat(statusPercentage.ANSWERED),
      color: "#FF00FF",
    },
    { title: "AMD", value: parseFloat(statusPercentage.AMD), color: "	#FF4500" },
    { title: "DNC", value: parseFloat(statusPercentage.DNC), color: "black" },
    {
      title: "INTERACTION",
      value: parseFloat(statusPercentage.INTERACTION),
      color: "black",
    },
  ];

	//show chart using chart js

	return (
    <>
      {api === null ? (
        <div className="text-white text-3xl">
          Please Insert Your API KEY At Your Profile Tab
        </div>
      ) : loader ? (
        <Spin
          tip="Loading"
          size="large"
          className="flex items-center justify-center mt-32 text-white"
        >
          <div className="content " />
        </Spin>
      ) : (
        <div className="text-white flex flex-col items-center gap-10">
          {/* <h1>JSON Data</h1>
			<p>Total Duration: {totalDuration} seconds</p>
			<p>Status Percentage:</p>
			<ul>
				{statusValues.map((status) => (
					<li key={status}>
						{status}: {statusPercentage[status]}%
					</li>
				))}
			</ul>
			<p>Total Calls: {totalCalls}</p>
			<p>Today Duration: {todayDuration}</p> */}
          <section className="flex flex-col lg:flex-row gap-6 mt-12">
            <div className="h-40 md:w-72 rounded shadow-md p-3 call-duration-bg text-2xl ">
              <span className="text-white">Total Call Duration</span>

              <div className="flex justify-center items-center text-gray-200 font-semibold mt-5">
                <CountUp
                  end={totalDuration}
                  prefix=""
                  duration={3}
                  suffix="  seconds"
                />
              </div>
            </div>
            <div className="h-40 md:w-72 rounded shadow-md p-3 total-calls text-2xl ">
              <span className="text-white">Total Calls</span>

              <div className="flex justify-center items-center text-gray-200 font-semibold mt-5">
                <CountUp end={totalCalls} prefix="" duration={3} suffix="  " />
              </div>
            </div>
            <div className="h-40 md:w-72 rounded shadow-md p-3 toda-call-duration  text-2xl ">
              <span className="text-white">Today Call Duration</span>

              <div className="flex justify-center items-center text-gray-200 font-semibold mt-5">
                <CountUp
                  end={todayDuration}
                  prefix=""
                  duration={3}
                  suffix="  seconds"
                />
              </div>
            </div>
          </section>
          <section className="h-96">
            <PieChart
              data={pieChartData}
              animate
              animationDuration={500}
              animationEasing="ease-out"
              label={({ dataEntry }) =>
                `${dataEntry.title}: ${dataEntry.value}%`
              }
              labelStyle={{
                fontSize: "3px",
                fill: "#fff",
                fontFamily: "sans-serif",
              }}
              radiusRatio={0.6}
              // radius={PieChart.defaultProps.radius - 6}
              // segmentsShift={(index) => (index === 0 ? 6 : 0.5)}
            />
          </section>
        </div>
      )}
    </>
  );
};

export default Home;

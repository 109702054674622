import { Badge, Table } from "antd";
import React, { useEffect, useState } from "react";
import "./Table.css";
import { useAuth } from "../../Context/useAuth";

const CallsData = () => {
	// table style

	// table style
	/* fetch data */
	// const {user}= useAuth()
	const api = JSON.parse(localStorage.getItem("api"));
	const [dataOfCalls, setDataOfCalls] = useState([]);
	const dataSource = dataOfCalls.data;
	const apiKey = "PcGnmlCp9v3RBN2c3E0B3833vY09ju2aJFCR6bUx";

	useEffect(() => {
		fetch("https://arcadia.mobilize.ai/api/calls", {
			headers: {
				Authorization: `Bearer ${api?.getApi}`,
			},
		})
			.then((response) => response.json())
			.then((data) => {
				// Handle the API response data
				setDataOfCalls(data);
			})
			.catch((error) => {
				// Handle any errors that occur during the fetch request
				console.error(error);
			});
	}, [dataOfCalls, api]);
	console.log(api)
	/* fetch data */

	const columns = [
		{
			title: "Id",
			dataIndex: "id",
			key: "id",
		},
		{
			title: "Direction",
			dataIndex: "direction",
			key: "direction",
			render: (data) =>
				data === true ? (
					<Badge className="bg-green-400 px-2 py-1 rounded font-medium text-white">
						<span>True</span>
					</Badge>
				) : (
					<Badge className="bg-red-400 text-white px-2 py-1 rounded font-medium">
						<span>False</span>
					</Badge>
				),
		},
		/* {
			title: "Campaign_id",
			dataIndex: "campaign_id",
			key: "campaign_id",
		}, */
		{
			title: "From",
			dataIndex: "from",
			key: "from",
		},
		{
			title: "To",
			dataIndex: "to",
			key: "to",
		},
		{
			title: "Start",
			dataIndex: "start",
			key: "start",
		},
		{
			title: "End",
			dataIndex: "end",
			key: "end",
		},
		{
			title: "Status",
			dataIndex: "status",
			key: "status",
		},
	];

	return (
    <div className="z-40 shadow-lg rounded-md">
      <div className="overflow-x-auto">
        {api === null ? (
          <div className="text-white text-3xl">Please Insert Your API KEY At Your Profile Tab</div>
        ) : (
          <Table
            loading={dataSource ? false : true}
            // bordered={true}
            pagination={{
              position: ["bottomCenter"],
            }}
            dataSource={dataSource}
            columns={columns}
          />
        )}
      </div>
    </div>
  );
};

export default CallsData;

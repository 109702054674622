import React, { useState } from "react";
import { useAuth } from "../../Context/useAuth";
import { useNavigate } from "react-router-dom";
import { toast } from "react-hot-toast";
// import { Spin } from "antd";

function Login() {
	const { setUser } = useAuth();
	const navigate = useNavigate();
	const [name, setName] = useState("");
	const [password, setPassword] = useState("");
	const [loggedIn, setLoggedIn] = useState(false);

	const handleNameChange = (e) => {
		setName(e.target.value);
	};

	const handlePasswordChange = (e) => {
		setPassword(e.target.value);
	};

	const handleSubmit = (e) => {
		e.preventDefault();

		const providedPassword = "$Andrew123456#";

		if (password === providedPassword) {
			setLoggedIn(true);
			toast.success("Successfully Loggedin");
			// console.log("Logged in successfully!");
			localStorage.setItem("user", JSON.stringify({ name}));
			const storedUser = localStorage.getItem("user");
			setUser(JSON.parse(storedUser));
			setName(""); // Reset name field
			setPassword(""); // Reset password field
			navigate("/");
		} else {
			setLoggedIn(false);
			alert("Incorrect password. Please try again.");
		}
	};
	return (
    <div className="min-h-screen bg-[#390f64] flex flex-col items-center justify-center">
      <form onSubmit={handleSubmit} className="flex flex-col mt-0 p-6 gap-6 md:w-3/6 lg:w-2/6">
        <h2 className="text-white font-bold text-xl my-4">Login</h2>
        <label className="text-white text-start">Name:</label>
        <input
          className="rounded text-black text-center"
          placeholder="Andrew"
          type="text"
          // value={name}
          onChange={handleNameChange}
        />
        
        <label className="text-white text-start">Password:</label>
        <input
          required
          placeholder="Password"
          className="rounded text-black text-center"
          type="password"
          value={password}
          onChange={handlePasswordChange}
        />
        <button
          type="submit"
          className="bg-gray-300 px-2 py-1 font-semibold rounded w-40 mx-auto"
        >
          Submit
        </button>
      </form>
    </div>
  );
}

export default Login;

import React, { useState } from "react";
import { DatabaseOutlined } from "@ant-design/icons";
import { Layout, Menu } from "antd";
import { Header } from "antd/es/layout/layout";
import { Link, Outlet, useNavigate } from "react-router-dom";
import { Toaster } from "react-hot-toast";

const { Content, Sider } = Layout;

const Dashboard = () => {
   const user = JSON.parse(localStorage.getItem("user"));
	const navigate = useNavigate();
	const handleLogout = () => {
    localStorage.removeItem("user");
    localStorage.removeItem("api");
		navigate("/login");
	};
	const [collapsed, setCollapsed] = useState(false);
	return (
    <Layout>
      <Sider
        collapsible
        collapsed={collapsed}
        onCollapse={(value) => setCollapsed(value)}
        className="min-h-screen"
        breakpoint="lg"
        collapsedWidth="80"
        onBreakpoint={(broken) => {}}
      >
        <div className="text-white text-xl relative">
          <h2
            className="absolute font-medium"
            style={{
              marginBottom: "-32px",
              marginTop: "18px",
              marginLeft: "40px",
              letterSpacing: "4px",
            }}
          >
            Dashboard
          </h2>
        </div>
        <Menu theme="dark" mode="inline">
          <Menu.Item
            className="bg-color"
            key="1"
            icon={<DatabaseOutlined />}
            style={{
              // color: "#fff",
              // fontWeight: "600",

              marginTop: "64px",
              fontSize: "22px",
              // letterSpacing: "4px",
            }}
          >
            <Link to="/">Profile</Link>
          </Menu.Item>
          <Menu.Item
            className="bg-color"
            key="2"
            icon={<DatabaseOutlined />}
            style={{
              // color: "#fff",
              // fontWeight: "600",

              
              fontSize: "22px",
              // letterSpacing: "4px",
            }}
          >
            <Link to="/overview">Overview</Link>
          </Menu.Item>
          <Menu.Item
            className="bg-color"
            key="3"
            icon={<DatabaseOutlined />}
            style={{
              // color: "#fff",
              // fontWeight: "600",
              // marginTop: "64px",
              fontSize: "22px",
              // letterSpacing: "4px",
            }}
          >
            <Link to="/calls">Calls</Link>
          </Menu.Item>
        </Menu>
      </Sider>
      <Layout>
        {
          <Header
            style={{
              display: "flex",
              alignItems: "center",
              justifyContent: "flex-end",
              cursor: "pointer",
            }}
          >
            <div className="flex">
              <p className="text-white mr-3">{user?.name}</p>
              <p
                onClick={() => handleLogout()}
                style={{ display: "flex", color: "whitesmoke" }}
              >
                Logout
              </p>
              {/* <h2 className="text-xl tracking-[4px]">Dashboard</h2> */}
            </div>
          </Header>
        }
        {/* style={{ backgroundColor: "#390f64" }} */}
        <Content style={{ backgroundColor: "#390f64" }}>
          {/* <div className="min-h-screen p-0 md:p-2 lg:p-4 bg-[#390f64] text-white">
            <AllCalls />
          </div> */}
          <Outlet />
        </Content>
      </Layout>
      <Toaster />
    </Layout>
  );
};

export default Dashboard;

import React from "react";
import CallsData from "./CallsData";

const AllCalls = () => {
	return (
		<div className="p-2">
			<CallsData />
		</div>
	);
};

export default AllCalls;

import { createContext, useContext, useState } from "react";


const AuthContext = createContext({})
  
const AuthProvider = ({ children }) => {
  const [user, setUser] = useState({});
  const [api, setApi]=useState("")
    return (<AuthContext.Provider value={{user, setUser, api, setApi}}>
        {children}
    </AuthContext.Provider>)
}
export const useAuth = () => {
  return useContext(AuthContext);
};

export default AuthProvider;